import React from 'react';
import { useLocation, Route } from 'react-router-dom';

// snipet
import Header from '../components/snippet/Header';
import HeaderLogin from '../components/snippet/HeaderLogin';
import Footer from '../components/snippet/Footer';

import Homepage from '../components/Homepage';
import HomepageSupplier from '../components/HomepageSuppliers';
import HomepageAdvertiseLanding from '../components/HomepageAdvertiseLanding';
import Sitemap from "../components/Sitemap";
import Login from "../components/Login";
import About from '../components/about';
import ContactUs from '../components/ContactUs';
import FAQ from '../components/Faq';
import TermsConditions from '../components/TermsConditions';
import Policy from '../components/Policy';
import OurBlog from '../components/OurBlog';
import SingleBlog from '../components/SingleBlog';
import Search from '../components/Search';
import GetStarted from '../components/GetStarted';
import ForgotPassword from '../components/ForgotPassword';
import Webinar from '../components/webinar';

import OrganizerSignUp from "../components/organizer/SignUp";
import OrganizerFewMoreDetails from "../components/organizer/FewMoreDetails";

import FreelancerSignUp from "../components/freelancer/SignUp";
import FreelancerFewMoreDetails from "../components/freelancer/FewMoreDetails";

import SupplierGetstarted from "../components/GetStartedSupplier";
import SupplierSignup from "../components/supplier/SignUp";
import SupplierFewMoreDetails from "../components/supplier/FewMoreDetails";


import NotFound from '../components/notFound'
import Resourcespage from '../components/OurResources';
import HomePageNew from '../components/HomePageNew';
// import FreelancerDashboard from "../components/freelancer/dashboard";
// import OrganizerDashboard from "../components/organizer/dashboard";
// import SupplierDashboard from "../components/supplier/dashboard";


const Primarylayout = ({view}) => {
    const { pathname } = useLocation();
    // console.log(pathname);
    const userAccess = pathname === '/forgot-password' || pathname === '/forgot-password/' || pathname === '/login' || pathname === '/login/' || pathname === '/get-started' || pathname === '/s/get-started' || pathname === '/e/sign-up-today-and-post-your-first-job' || pathname === '/f/sign-up-today-and-find-your-first-job' || pathname === '/s/sign-up-today-supplier' || pathname === '/e/few-more-details' || pathname === '/f/few-more-details' || pathname === '/s/few-more-details' ;

    let AuthFree = localStorage.getItem('auth_empplyFreeUID');
    let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
    let Authsup = localStorage.getItem('auth_empplySplierUID');
    let Auths = AuthFree || AuthOrg || Authsup;

    return (
        <div className={`page-data ${view}`}>
            {userAccess ? <HeaderLogin /> : <Header />}

            {pathname === "/old" && <Homepage />}
            {pathname === "/" && <HomePageNew />}
            {pathname === "/sitemap" && <Sitemap />}
            {pathname === "/about" && <About />}
            {pathname === "/about-us" && <About />}
            {pathname === "/contact-us" && <ContactUs />}
            {pathname === "/faq" && <FAQ />}
            {pathname === "/terms-conditions" && <TermsConditions />}
            {pathname === "/privacy-policy" && <Policy />}
            {pathname === "/blog" && <OurBlog />}
            {pathname === "/resources" && <Resourcespage />}
            <Route path="/post/:id">
                <SingleBlog />
            </Route>
            <Route path="/search" render={(props) => <Search {...props}/>}></Route>
            {/* {pathname === "/search" && <Search />} */}
            {pathname === "/supplier" && <HomepageSupplier />}
            {pathname === "/advertise" && <HomepageAdvertiseLanding />}


            {pathname === "/login" && <Login />}
            {pathname === "/login/" && <Login />}
            {pathname === "/get-started" && <GetStarted />}
            {pathname === "/forgot-password" && <ForgotPassword />}  
            {pathname === "/forgot-password/" && <ForgotPassword />}  
            {pathname === "/s/get-started" && <SupplierGetstarted />}  
            
            {pathname === "/e/sign-up-today-and-post-your-first-job" && <OrganizerSignUp />}
            {pathname === "/f/sign-up-today-and-find-your-first-job" && <FreelancerSignUp />}
            {pathname === "/s/sign-up-today-supplier" && <SupplierSignup />}  

            {pathname === "/e/few-more-details" && <OrganizerFewMoreDetails />}  
            {pathname === "/f/few-more-details" && <FreelancerFewMoreDetails />}  
            {pathname === "/s/few-more-details" && <SupplierFewMoreDetails />}  
            
            {pathname === "/webinars" && <Webinar />}
            {/* <Route path='*' component={NotFound} />  */}
            {/* {pathname === "*" && <NotFound />}  */}
            <NotFound view="landing" />
            {!Auths && <Footer />}
        </div>
    );
}

export default React.memo(Primarylayout);