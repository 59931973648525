import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutFormSupplier';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_live_4RpzdlTNdEzXnVoKsXJGD7RU00nxFFyy0v');

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe('pk_live_4RpzdlTNdEzXnVoKsXJGD7RU00nxFFyy0v');
  }
  return stripePromise;
}

export default function PaymentCheckoutSupplier({clientSecret}) {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret ?? '',
  };

  return (
    getStripe() ? (
        <Elements stripe={getStripe()} options={options}>
          <CheckoutForm />
        </Elements>
      ):(
        <div>Loading Stripe...</div>
      )
  );
};