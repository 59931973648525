import React, { useState } from 'react';
import { Redirect, useLocation, Route } from 'react-router-dom';


// snipet
import DashboardFreeHeader from '../components/freelancer/DashboardHeader';
import Search from '../components/snippet/SearchDashboardFree';



import FreelancerDashboard from "../components/freelancer/dashboard";
import FreelancerProfile from "../components/freelancer/Profile";
import FreelancerProfileChange from "../components/freelancer/ProfileChange";
import FreelancerProfileComplete from "../components/freelancer/ProfileComplete";
import FreelancerMembership from "../components/freelancer/MembershipPlan";
import FreelancerPayment from "../components/freelancer/Payment";
import FreelancerAccepted from "../components/freelancer/AcceptedJobs";
import FreelancerRequested from "../components/freelancer/RequestJobs";
import FreelancerCompleted from "../components/freelancer/CompletedJobs";
import FreelancerSingleJob from "../components/freelancer/SingleJob";
import FreelancerChat from "../components/freelancer/Chat";
import FreelancerChatInner from "../components/freelancer/ChatInner";
import FreelancerCalendar from "../components/freelancer/Calendar";
import FreelancerCalendarAvailability from "../components/freelancer/CalendarAvailability";
import FreelancerLeaveRating from "../components/freelancer/LeaveRating";
import FreelancerMyRating from "../components/freelancer/MyRating";
import FreelancerNotifications from "../components/freelancer/Notification";
import FreelancerSuppliers from "../components/freelancer/Suppliers";
import FreelancerSuppliersSingle from "../components/freelancer/SuppliersSingle";
import FreelancerSuppliersSingleOpen from "../components/freelancer/SuppliersSingleOpen";
import FreelancerFavourites from "../components/freelancer/MyFavourites";
// import FreelancerSearch from "../components/snippet/SearchDashboardFree";

// import NotFound from '../components/notFound'
import { Modal } from 'react-bootstrap';
import NotFound from '../components/notFound';
const PrimarylayoutFree = () => {
    // const [path, setPath] = useState(true);
    const {pathname} = useLocation();
    const [search, setSearch] = useState([]);
    const [show, setShow] = useState(false);
    // console.log(pathname)

    // useEffect(() => {
    //     if(pathname === "/"){
    //         setPath(true);
    //     }else{
    //         setPath(false);
    //     }
    // }, []);

    const sendSearch = (e) => {
        setSearch(e);
        // console.log(e)
        setShow(true);
    }

    let AuthFree = localStorage.getItem('auth_empplyFreeUID');
    // const AllJobs = search;

    if(!AuthFree){
        return <Redirect push to="/" />
    }

    // if(show){
    //     return <Redirect to="/f/search" />
    // }

    const handleClose = () => setShow(false);

    // console.log(AuthFree)
    return (
        <div id="wrapper">
            {AuthFree && <DashboardFreeHeader getSearch={(e) => sendSearch(e)} />}
                            
            {/* {AllJobs && AllJobs[0] ? 
                <Search jobs={search} /> : 
                (show ? <Search jobs={search} /> : 
                    ( */}
                        <>
                            
                            {/* {pathname === "/f/search" && <Search jobs={search} />} */}
                            {pathname === "/f/profile" && <FreelancerProfile />}
                            {pathname === "/f/change-password" && <FreelancerProfileChange />}
                            {pathname === "/f/complete-profile" && <FreelancerProfileComplete />}
                            {pathname === "/f/membership" && <FreelancerMembership />}
                            {pathname === "/f/payment" && <FreelancerPayment />}
                            {pathname === "/f/accepted" && <FreelancerAccepted />}
                            {pathname === "/f/offers" && <FreelancerRequested />}
                            {pathname === "/f/completed" && <FreelancerCompleted />}
                            <Route path="/f/job/:id">
                                <FreelancerSingleJob />
                            </Route>
                            {pathname === "/f/chat" && <FreelancerChat />}
                            <Route path="/f/user/:id">
                                <FreelancerChatInner />
                            </Route>
                            {pathname === "/f/calendar" && <FreelancerCalendar />}
                            {pathname === "/f/availability" && <FreelancerCalendarAvailability />}
                            {pathname === "/f/leave-rating" && <FreelancerLeaveRating />}
                            {pathname === "/f/my-rating" && <FreelancerMyRating />}
                            {pathname === "/f/my-favourites" && <FreelancerFavourites />}
                            {pathname === "/f/notifications" && <FreelancerNotifications />}
                            {pathname === "/f/suppliers" && <FreelancerSuppliers />}
                            <Route path="/f/supplier/:id">
                                {AuthFree ? <FreelancerSuppliersSingle /> : <FreelancerSuppliersSingleOpen />}
                            </Route>
                            {pathname === "/" && <FreelancerDashboard />}
                            {/* {pathname === "/f/search" && <Search jobs={search} />} */}

                            {/* <Route path="*" component={NotFound} /> */}
                            <NotFound view="dashboard" />
                        </>
                    {/* )
                )
            } */}


            <Modal show={show} centered={true} size="lg" onHide={handleClose}>
                <Modal.Header className='border-0' closeButton>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0 px-0 px-lg-3'>
                    <Search modal={handleClose} results={search} />
                </Modal.Body>
            </Modal>

        </div>
    );
}

export default React.memo(PrimarylayoutFree);