import React, { useState } from 'react';
import { Redirect, useLocation, Route } from 'react-router-dom';


// snipet
import DashboardOrgHeader from '../components/organizer/DashboardHeader';
import Search from '../components/snippet/SearchDashboardOrg';

import OrganizerDashboard from "../components/organizer/dashboard";
import OrganizerProfile from "../components/organizer/Profile";
import OrganizerProfileChange from "../components/organizer/ProfileChange";
import OrganizerJobPost from "../components/organizer/PostAJob";
import OrganizerJobPostUpdate from "../components/organizer/PostAJobUpdate";
import OrganizerCurrentJobs from "../components/organizer/CurrentJobs";
import OrganizerMemberShip from "../components/organizer/MembershipPlan";
import OrganizerPayment from "../components/organizer/Payment";
import OrganizerRating from "../components/organizer/MyRating";
import OrganizerPreviousJobs from "../components/organizer/PreviousJobs";
import OrganizerMessage from "../components/organizer/Chat";
import OrganizerMessageInner from "../components/organizer/ChatInner";
import OrganizerCalendar from "../components/organizer/Calendar";
import OrganizerThankYou from "../components/organizer/ThankYou";
import OrganizerSingleJob from "../components/organizer/SingleJob";
import OrganizerLeaveRating from "../components/organizer/LeaveRating";
import OrganizerFreelancerSingle from "../components/organizer/FreelancerSingle";
import OrganizerNotifications from "../components/organizer/Notification";
import OrganizerSuppliers from "../components/organizer/Suppliers";
import OrganizerSuppliersSingle from "../components/organizer/SuppliersSingle";
import OrganizerSuppliersSingleOpen from "../components/organizer/SuppliersSingleOpen";
import OrganizerFavourites from "../components/organizer/MyFavourites";
import ThankYouPayment from "../components/organizer/ThankYouPayment";

// import NotFound from '../components/notFound'
import { Modal } from 'react-bootstrap';
import NotFound from '../components/notFound';
// import NotFound from '../components/notFound';

const PrimarylayoutOrg = () => {
    const {pathname} = useLocation();
    const [search, setSearch] = useState([]);
    const [show, setShow] = useState(false);

    const sendSearch = (e) => {
        setSearch(e);
        // console.log(e)
        setShow(true);
    }

    let AuthOrg = localStorage.getItem('auth_empplyOrgUID');
    // const AllJobs = search;

    if(!AuthOrg){
        return <Redirect push to="/" />
    }
    const handleClose = () => setShow(false);
    
    return (
        <div id="wrapper">

            {AuthOrg && <DashboardOrgHeader getSearch={(e) => sendSearch(e)} />}
            
            {/* {AllJobs && AllJobs[0] ? 
                <Search jobs={search} /> : 
                (show ? <Search jobs={search} /> : 
                    ( */}
                    {/* <Router>
                        <Switch> */}
                        <>
                            {/* {pathname === "/e/search" && <Search jobs={search} />} */}
                            <Route path="/e/profile" component={OrganizerProfile} />
                            <Route path="/e/change-password" component={OrganizerProfileChange} />
                            <Route path="/e/job-post" component={OrganizerJobPost} />
                            {/* {pathname === "/e/job-post-update/:id" && <OrganizerJobPostUpdate />} */}
                            <Route path="/e/job-post-update/:id">
                                <OrganizerJobPostUpdate />
                            </Route>
                            <Route path="/e/current-jobs" component={OrganizerCurrentJobs} />
                            <Route path="/e/membership" component={OrganizerMemberShip} />
                            <Route path="/e/payment" component={OrganizerPayment} />
                            <Route path="/e/my-rating" component={OrganizerRating} />
                            <Route path="/e/previous-jobs" component={OrganizerPreviousJobs} />
                            <Route path="/e/chat" component={OrganizerMessage} />
                            <Route path="/e/user/:id">
                                <OrganizerMessageInner />
                            </Route>
                            <Route path="/e/calendar" component={OrganizerCalendar} />
                            <Route path="/e/thank-you" component={OrganizerThankYou} />
                            <Route path="/e/thank-you-payment" component={ThankYouPayment} />
                            <Route path="/e/job/:id">
                                <OrganizerSingleJob />
                            </Route>
                            <Route path="/e/leave-rating" component={OrganizerLeaveRating} />
                            {/* {pathname === "/e/freelancer/:id" && <OrganizerFreelancerSingle />} */}
                            <Route path="/e/freelancer/:id">
                                <OrganizerFreelancerSingle />
                            </Route>
                            <Route path="/e/notifications" component={OrganizerNotifications} />
                            <Route path="/e/suppliers" component={OrganizerSuppliers} />
                            <Route path="/e/my-favourites" component={OrganizerFavourites} />
                            <Route path="/e/supplier/:id">
                                {AuthOrg ? <OrganizerSuppliersSingle /> : <OrganizerSuppliersSingleOpen />}
                            </Route>
                            {/* <Route path="/" component={} /> */}
                            {pathname === "/" && <OrganizerDashboard />}
                            <NotFound view="dashboard" />
                        </>
                        {/* </Switch>
                    </Router> */}
                    {/* )
                )} */}

                {/* <Route path="*" component={NotFound} /> */}

                <Modal show={show} centered={true} size="lg" onHide={handleClose}>
                    <Modal.Header className='border-0' closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='pt-0 px-0 px-lg-3'>
                        <Search modal={handleClose} results={search} />
                    </Modal.Body>
                </Modal>
        </div>
    );
}

export default React.memo(PrimarylayoutOrg);